const footer = $(`<div class="footer-main">
  <div class="box">
    <p class="title">打造新青年的分期购物商城</p>
    <div class="footer-logo title">赢爱购</div>
    <p class="tips">深圳市赢爱购贸易有限公司</p>
    <div class="tel-wrap">
      <div class="tel-icon"></div>
      <p class="tel">952592 （9:00-18:00）</div>
    </div>
    <div class="line"></div>
    <div class="tips-2"><i></i><p><a href="https://beian.miit.gov.cn" target="_blank">Copyright ©2022 粤ICP备19036328号</a></p></div>
  </div>
</div>`)
export default footer
