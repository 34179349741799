import './index.less'
import '../component/footer.less'
import '../component/header.less'
import '../style/common.css'
import footer from '../component/footer.js'
import header from '../component/header.js'
import { go } from '../common/common.js'
require('./index.html')
go()
$('.header').append(header)
$('.footer').append(footer)

console.log(header)
$('.a1').addClass('active')
